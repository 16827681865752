
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Backdrop, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, ThemeProvider, Toolbar, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import { PermissionTicket } from "models/PermissionTicket"
import { PermissionRequest } from "models/PermissionRequest"
import { ResourceType } from "models/ResourceType"
import { AppScopes, ScopeUtils } from "models/PermissionScopes"
import { Bootstrapper } from "Bootstrapper"
import { Context } from "./Context"
import CircularProgress from "components/progress/CircularProgress"
import Link from "components/buttons/Link"
import Alert from "components/alerts/Alert"
import Dashboard from "components/dashboard/Dashboard"
import { NavItem } from "components/dashboard/Navbar"

const Account = (props: any) => {
    const navigate = useNavigate();
    const context = Bootstrapper.getInstance<Context>(Context);
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const userId = userService.getActiveUserId();

    const [hasLoaded, setHasLoaded] = useState<boolean>(false);
    const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>(true);
    const [isIntegrationsEnables, setIsIntegrationsEnables] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const location = useLocation();

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }




    useEffect(() => {
        context.isBusyEmitter.subscribe(val => {
            setIsBusy(val);
        })
        loadDataAsync();
    }, []);

    const loadDataAsync= async() => {
        setHasLoaded(false);
        context.setBusyIndicator(true);
        const user = await userService.getUserAsync(userId);
        setIsEmailConfirmed(user.emailConfirmed);
        setIsIntegrationsEnables(user.enableDmsIntegrations);
        context.setBusyIndicator(false);
        setHasLoaded(true);
    }

    const getNavItems = () => {
        const items: NavItem[] = [
            new NavItem('Account', '/account'),
            new NavItem('Profile', '/account/profile'),
            new NavItem('Organizations', '/account/organizations'),
        ];

        if(isIntegrationsEnables){
            items.push(new NavItem('Integrations', '/account/integrations'));
        }
        return items;
        
    }


    return(
        <Dashboard
            titleBar="Welcome back"
            navItems={getNavItems()}
            alerts={!isEmailConfirmed && <Alert flat severity="warning">Please confirm your email <Link underline="always" color="inherit" component={NavLink} to={'/email-confirmation'}>here</Link></Alert>}
            isBusy={isBusy}
        >
            {hasLoaded && <Outlet />}
        </Dashboard>
    )

}


export default Account