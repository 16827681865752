import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import Home from './views/home/Home';
import Header from './components/header/Header';
import { ThemeProvider } from '@emotion/react';
import { MyTheme } from './MyTheme';
import Footer from 'components/footer/Footer';
import Downloads from 'views/downloads/Downloads';
import RouteComposition from 'routes/RouteComposition';
import { BrowserRouter, useNavigate, useLocation } from 'react-router-dom';
import { UseActiveUserIdStore, Subscription as ActiveUserIdSubscription } from "stores/ActiveUserIdStore";
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import { UseAppInitStore, Update as InitUpdate } from 'stores/AppInitStore';
import { UseToastStore, Update as ToastUpdate } from 'stores/ToastStore';

import { TenantService } from 'services/TenantService';
import Backdrop from 'components/backdrop/Backdrop';
import CircularProgress from 'components/progress/CircularProgress';
import Box from 'components/layout/box/Box';
import ScrollToTop from 'hooks/ScrollToTop';
import { Snackbar } from '@mui/material';
import { Toast } from 'stores/models/Toast';
import ErrorBoundary from 'views/ErrorBoundary';
import ConfirmDialog from 'components/modals/ConfirmDialog';
import { AppContext, DialogSettings } from 'AppContext';
import { Bootstrapper } from 'Bootstrapper';

const theme = MyTheme();
const tenantService = new TenantService();

const App = (props: any) => {
  const appInit = UseAppInitStore();
  const toast = UseToastStore();
  const busyIndicator = UseBusyIndicatorStore();
  const isBusy = busyIndicator.isBusy;
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const [dialogSettings, setDialogSettings] = useState<DialogSettings>(null);

  useEffect(() => {
    var context = Bootstrapper.getInstance<AppContext>(AppContext);
    context.displayDialogEmitter.subscribe((val) => {
      setDialogSettings(val);
    })
    // ActiveUserIdSubscription(val => {
    //   console.log("app sub hit", val);
    //     if(!appInit)
    //       return;
    //     onActiveUserChangeAsync(val);
    // })
  }, []);

  // const onActiveUserChangeAsync = async(val: string) => {
  //   if(val == null){
  //       UpdateOrganizationId(null);
  //   }
  //   else{
  //       setIsLoadingOrg(true);
  //       var tenant = await tenantService.getOwnerOrganization();
  //       console.log("owner tenant", tenant);
  //       UpdateOrganizationId(tenant?.id);
  //       setIsLoadingOrg(false);
  //   }
  // }


  const getView = (initializing: boolean) => {

    const routeHasFlex = (route: string) => {
      if(route?.startsWith('/admin') || route?.startsWith('/account') || route?.startsWith('/organization'))
        return false;

      switch(route){
        case '/privacy':
        case '/':
        case '/home':
          return false;
        default:
          return true;

      }
    }

    if(initializing){
      return(
        <>
          <Backdrop open={!appInit || isBusy}>
              <CircularProgress label="Loading" sx={{color: theme.palette.common.white}} />
          </Backdrop>
          <Box flexGrow={1} backgroundColor={theme.palette.primary.main}></Box>
        </>
      )
    }
    return(
      <>
        <Backdrop open={isBusy} />
        <Header />

        <Box position='relative' display={routeHasFlex(currentRoute) ? 'flex' : 'block'} flexGrow={1} justifyContent={appInit ? "flex-start" : "center"}>
          {isBusy &&<Box display='flex' position='absolute' width='100%' height='100%'><CircularProgress label={busyIndicator.label} /></Box>}
          <RouteComposition />
        </Box>
        <Footer />
      </>
        )
  }

  const closeDialog = () => {
    setDialogSettings(null);
  }

  const beforeHandleError = () => {
    BusyUpdate(false);
    if(!appInit)
      InitUpdate(true);
  }

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
      <ErrorBoundary beforeHandleError={beforeHandleError} navigate={navigate}>
          <ScrollToTop/>
          <Box minHeight="100vh" display="flex" flexDirection="column">
            {getView(!appInit)}
          </Box>
          <Snackbar
            open={toast.open}
            onClose={() => ToastUpdate(new Toast(false))}
            autoHideDuration={toast.autoHide ? 2000 : null}
            message={toast.message}
          />
          {dialogSettings && <ConfirmDialog
            title={dialogSettings.title} 
            onClose={closeDialog}
            onResult={dialogSettings.onResult} 
            >
              {dialogSettings.msg}
          </ConfirmDialog>}
        </ErrorBoundary>
      </ThemeProvider>


    </div>
  );
}

export default App;
