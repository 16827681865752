import { ResourceType } from './ResourceType';

export abstract class PermissionRequest {
    constructor(type: ResourceType){
        this.resourceType = type;
    }
    resourceType: ResourceType;
    resourceOwnerId: string;
    targetUserId: string;
    RequestingUserId: string
    RequestedScopes: string[];
}

export class TenantAccountPermissionRequest extends PermissionRequest{
    constructor(type: ResourceType = ResourceType.TenantAccount){
        super(type)
    }
    public tenantId: string;
}

export class UserAccountPermissionRequest extends PermissionRequest{
    constructor(type: ResourceType = ResourceType.UserAccount){
        super(type)
    }
}
