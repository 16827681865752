export class LoginDevice {
    id: string;
    applicationClientType: number;
    name: string;
    lastAccessed: string;
    expires: string;
    
    public static isDesktopClientType(type: number): boolean{
        return type === 0;
    }

    public static printClientType(type: number): string {
        return LoginDevice.isDesktopClientType(type) ? "Word Plugin" : "Website";
    }
}