
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"




const Login = (props: any) => {
    const navigate = useNavigate();
    const userService = new UserService();
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    const onSubmitAsync = async() => {
        setValidationMsg("");
        setCanSubmit(false);
        var result = await userService.loginAsync(email, password).catch((err: Error) => {
            setValidationMsg(err.message || "The email or password is incorrect");
        });
        
        if(result)
            navigate("/account")
        
        setCanSubmit(true);
    }

    return(
        <Section sx={{flexGrow: 1}} id="section-plans" bgColor={bg2}>
            <Container maxWidth="xs">
                <Animation overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <Text mb={1} variant="caption">Don't have an account? <Link component={NavLink} variant="text" to={'/register'}>Register</Link></Text>
                        
                        <Form 
                        validationMsg={validationMsg}
                        action={<LoadingButton loading={!canSubmit} disabled={!canSubmit} onClick={() => onSubmitAsync()} fullWidth>Submit</LoadingButton>}
                        title="Login">
                            <FieldCollection>
                                <TextField 
                                    name="username" id="username" value={email} onChange={(ele: any) => setEmail(ele.target.value)} label="Email" {...props} />
                                <TextField 
                                    name="password"
                                    id="password"
                                    type="password"
                                    value={password} 
                                    onChange={(ele: any) => setPassword(ele.target.value)} 
                                    label="Password"
                                    helperText={ <Link tabIndex="-1" sx={{}} component={NavLink} to="/password-reset-link">Forgot password?</Link>}
                                    helperTextProps={{sx: {marginLeft: 0}}}
                                    {...props}
                                    />
                                
                            </FieldCollection>
                        </Form>
                    </div>

                </Animation>
            </Container>
        </Section>
    )

}


export default Login