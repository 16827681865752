
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, Table, TableBody, TableCell, TableHead, TableRow, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import { Bootstrapper } from "Bootstrapper"
import Link from "components/buttons/Link"
import LoadingButton from "components/buttons/LoadingButton"
import Menu from "components/menu/Menu"
import MenuItem from "components/menu/MenuItem"
import { LoginDevice } from "models/LoginDevice"
import Checkbox from "components/toggle/checkbox/Checkbox"
import { DialogUtil } from "utils/DialogUtil"
import { UseBusyIndicatorStore, Update as BusyUpdate } from 'stores/BusyIndicatorStore';
import { CommonUtil } from "utils/CommonUtil"




const ManageDevicesButton = (props: any) => {
    const {userId, onClose, tenantId, devices, open, hideButton, size, children, ...rest} = props;
    const btnLabel = children ?? "Manage Devices";
    const btnSize = size;
    const dialogUtils = new DialogUtil();

    const navigate = useNavigate();
    const theme = MyTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const userService = new UserService();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [validationMsg, setValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [isOpen, setIsOpen] = useState<boolean>(Boolean(false));

    const [allDevices, setAllDevices] = useState<LoginDevice[]>(devices ?? []);


    useEffect(() => {
        if(open)
            loadDevicesAsync();
    }, []);

    const setDevicesAsync = async() => {
        BusyUpdate(true);
        var devices = await userService.getLoginDevicesAsync(userId, tenantId);
        setAllDevices(devices);
        BusyUpdate(false);
    }

    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }        


    const logoutOfDevicesAsync = async(devices: LoginDevice[], userId: string, tenantId: string) => {
        BusyUpdate(true);
        setCanSubmit(false);
        const deviceIds = devices.map(d => d.id);
        var summary = await userService.logoutOfDevicesAsync(userId, deviceIds, tenantId);
        if(summary.logoutRequired){
            var result = await userService.logoutAsync();
            if(result){
                navigate("/");    
            }
        }
        else{
            devices = await userService.getLoginDevicesAsync(userId, tenantId);
            setAllDevices(devices);    
        }
        setCanSubmit(true);
        BusyUpdate(false);
    }

    const onSubmitLogoutOfDevicesAsync = async(devices: LoginDevice[])=>{
        if(!canSubmit)
            return;
        
        const singleDeviceMsg = `Are you sure you want to log out of this device: ${devices[0].name}?`
        const allDeviceMsg = `Are you sure you want to log out of these devices (${devices.length})?`
        const msg = devices.length === 1 ? singleDeviceMsg : allDeviceMsg;
        dialogUtils.confirmDialog(
            msg,
            async(confirm) => {
                if(!confirm)
                    return;

                await logoutOfDevicesAsync(devices, userId, tenantId);
            }
        )

    }



    const getDeviceName = (name: string) => {
        if(name === "")
            return "Unknown (Not available)";
        if(name === null)
            return "Unknown";
        return name;
    }


    const getDeviceList = (orgs: LoginDevice[]) => {
        
        return(
                <Table className={isMobile ? "responsive-table" : "null"} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Device</TableCell>
                            <TableCell align='left'>Zibbit Platform</TableCell>
                            <TableCell align="center">Last Accessed</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orgs.map(device => {
                            return (
                                <TableRow 
                                    // component={Link} to={`/organization/${org.id}`}
                                    key={device.id}
                                >
                                    <TableCell data-label="Device" component="th" scope="row">{getDeviceName(device.name)}</TableCell>
                                    <TableCell data-label="Platform" align='left' scope="row">{LoginDevice.printClientType(device.applicationClientType)}</TableCell>
                                    <TableCell data-label="Last Accessed" align="center" scope="row">{device.lastAccessed ? CommonUtil.printDate(new Date(device.lastAccessed)) : ""}</TableCell>

                                    <TableCell data-label="Action" align={isMobile ? 'left' : "right"}>
                                        <Button size="small" disabled={!canSubmit} onClick={() => onSubmitLogoutOfDevicesAsync([device])}>Logout</Button>
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
        )
    }

    const onCloseModal = () => {
        setIsOpen(false);
        setCanSubmit(true);
        setValidationMsg(null);
        onClose && onClose();
    }

    const loadDevicesAsync = async() => {
        setIsOpen(true);
        await setDevicesAsync();

    }

    const hasDevices = allDevices?.length > 0;

    return(
        <>
        {!hideButton && <Button size={btnSize} onClick={() => loadDevicesAsync()} {...rest}>{btnLabel}</Button>}
        <Dialog
            title="Manage Devices"
            maxWidth="md" 
            open={isOpen} 
            onClose={() => onCloseModal()}>
                <Form flat validationMsg={validationMsg || ""}>
                    <Stack spacing={1}>
                        <div>
                            {hasDevices && <Button onClick={() => onSubmitLogoutOfDevicesAsync(allDevices)}>Logout: all devices</Button>}
                        </div>

                        <Paper>
                            {hasDevices && getDeviceList(allDevices.filter(d => !LoginDevice.isDesktopClientType(d.applicationClientType)))}
                            {!hasDevices && <Text pt={1}>No devices</Text>}
                        </Paper>
                    </Stack>
                </Form>
        </Dialog>
        </>
    )

}


export default ManageDevicesButton