

export class CommonUtil {

    public printDate(date: Date): string{
        return CommonUtil.printDate(date);
    }

    public static printDate(date: Date): string{
        return date.toLocaleDateString();
    }

    public static isExpired(date: Date, current: Date = null): boolean{
            var current = current ?? new Date();
            var expires = new Date(date);
            var isExpired = current > expires;
            return isExpired;
    }

    public timeoutAsync(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public CopyToClipboard(content: string){
        window.navigator.clipboard.writeText(content)
    }

    isInViewport(ele: HTMLElement): boolean {
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
        var rect = ele.getBoundingClientRect();
        var result = rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (viewportHeight) &&
        rect.right <= (viewportWidth);
        return result;

    }

    public static isString(myVar: any){
        return typeof myVar === 'string' || myVar instanceof String;
    }

    public static isNullOrEmpty = (str: string) => {
        return str == null || str == "";
    }

}