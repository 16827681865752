
import { CSSProperties } from "@mui/material/styles/createTypography"
import { IdentityClient } from "api-clients/IdentityClient"
import { PlansClient } from "api-clients/PlansClient"
import { ProductInfoService } from "services/ProductInfoService"
import Container from "components/layout/Container"
import Grid from 'components/layout/grid/Grid'
import Item from 'components/layout/grid/Item'
// import './home.scss';
import Title from "components/labels/Title"
import Subtitle from "components/labels/SubHeading"
import Page from "components/layout/Page"
import Section from "components/layout/Section"
import Button from "components/buttons/Button"
import Box from "components/layout/box/Box"
import Heading from "components/labels/Heading"
import Text from 'components/labels/Text';

import CardFeature from 'views/home/CardFeature'
import { ColorsUtil, MyTheme } from "MyTheme"
import { Avatar, Card, CardActions, CardContent, CardHeader, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Paper, Stack, Step, StepButton, StepLabel, Stepper, useMediaQuery, Zoom } from "@mui/material"
import React, { useEffect, useRef, useState, VideoHTMLAttributes } from "react"
import useVisibility from "hooks/UseVisibility"
import Animation from "components/animations/Animation"
import SubHeading from "components/labels/SubHeading"
import TitleBlock from "components/layout/TitleBlock"
import Icon from "components/icons/Icon"
import { VideoMarker, VideoMarkersController } from "utils/VideoUtil"
import Modal from "components/modals/Dialog"
import Dialog from "components/modals/Dialog"
import { Plan } from "models/Plan"
import { Price } from "models/Price"
import FieldCollection from "components/fields/fieldCollection/FieldCollection"
import TextField from "components/fields/textField/TextField"
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { UserService } from "services/UserService"
import Form from "components/forms/Form"
import TabControl, { TabPanel } from "components/tabs/TabControl"
import Tab from "components/tabs/Tab"
import Tabs from "components/tabs/Tabs"
import ExpansionPanel from "components/transitions/expansionPanel/ExpansionPanel"
import { Bootstrapper } from "Bootstrapper"
import { Context } from "./Context"
import LoadingButton from "components/buttons/LoadingButton"
import { Toast } from "stores/models/Toast"
import { UseToastStore, Update as ToastUpdate } from 'stores/ToastStore';
import ManageDevicesButton from "views/authentication/ManageDevicesButton"
import Link from "components/buttons/Link"


const Profile = (props: any) => {
    const navigate = useNavigate();
    const userService = new UserService();
    
    const theme = MyTheme();
    const bg1 = theme.palette.grey[200];
    const bg2 = theme.palette.grey['100'];
    const context = Bootstrapper.getInstance<Context>(Context);
    const userId = userService.getActiveUserId();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [isAnimationChaining, setIsAnimationChaining] = useState<boolean | null>(null);
    const [generalValidationMsg, setGeneralValidationMsg] = useState<string>("");
    const [contactValidationMsg, setContactValidationMsg] = useState<string>("");
    const [canSubmit, setCanSubmit] = useState<boolean>(true);
    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();


    const handleAnimationChain = () => {
        setIsAnimationChaining(true);
    }

    const onUpdateProfileAsync = async() => {
        if(!canSubmit)
            return;
        setCanSubmit(false);
        await userService.updateProfileAsync(firstName, lastName);
        ToastUpdate(new Toast(true, "Successfuly updated profile"));
        setCanSubmit(true);
    }

    useEffect(() => {
        loadDataAsync();
    }, []);

    const loadDataAsync= async() => {
        context.setBusyIndicator(true);
        const user = await userService.getUserAsync(userId);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmail(user.email);
        setEmail(user.email);
        context.setBusyIndicator(false);
    }

    

    return(
        <Container sx={isMobile ? null : {margin: 0}} disableGutters maxWidth="xs">

        <Section disablePadding id="section-plans" bgColor={bg1}>
                <Animation duration="medium" overrideAnimation={isAnimationChaining} onEntering={handleAnimationChain} type='fade' direction='left' transitionDelay={0}>
                    <div>
                        <Grid container alignItems="stretch"spacing={2}>
                            <Grid flexGrow={1} item sm={12} md={12}>
                                <Item sx={{height: '100%'}}>
                                        <div style={{height: '100%'}}>
                                        <ExpansionPanel expanded={true} title="General">
                                            {/* <Link target='_blank' href={consentLink}>test</Link> */}
                    
                                            {/* {consentLink && <Link href={consentLink}>Test</Link>} */}
                                            <Form flat validationMsg={generalValidationMsg}>
                                                <FieldCollection>
                                                    <TextField value={firstName || ""} onChange={(ele: any) => setFirstName(ele.target.value)} label="First Name" {...props} />
                                                    <TextField value={lastName || ""} onChange={(ele: any) => setLastName(ele.target.value)} label="Last Name" {...props} />
                                                    <LoadingButton fullWidth loading={!canSubmit} disabled={!canSubmit} onClick={() => onUpdateProfileAsync()}>Submit</LoadingButton>
                                                </FieldCollection>
                                            </Form>
                                        </ExpansionPanel>
                                        </div>                                    
                                </Item>
                            </Grid>
                            <Grid flexGrow={1} item sm={12} md={12}>
                                <Item sx={{height: '100%'}}>
                                        <div style={{height: '100%'}}>
                                        <ExpansionPanel expanded={true} title="Contact">
                                            <Form flat
                                                validationMsg={contactValidationMsg}
                                                >
                                                    <FieldCollection>
                                                        <TextField isReadOnly disabled label="Email" value={email || ""} endAdornment={<Button component={NavLink} to="/email-reset/request" size="small">Change</Button>} />
                                                    </FieldCollection>
                                            </Form>
  
                                        </ExpansionPanel>
                                        </div>                                    
                                </Item>
                            </Grid>
                            <Grid flexGrow={1} item sm={12} md={12}>
                                <Item sx={{height: '100%'}}>
                                        <div style={{height: '100%'}}>
                                            <ExpansionPanel expanded={true} title="Privacy">
                                                <Form flat
                                                    validationMsg={generalValidationMsg}
                                                    >
                                                        <FieldCollection>
                                                            <TextField disabled isReadOnly value="Password" endAdornment={<Button component={NavLink} to="/password-reset-link" size="small">Change</Button>} />
                                                            <TextField disabled isReadOnly value="Devices" endAdornment={<ManageDevicesButton size="small" userId={userId}>Manage</ManageDevicesButton>} />
                                                        </FieldCollection>
                                                </Form>
                                            </ExpansionPanel>                                       
                                        </div>                                    
                                </Item>
                            </Grid>
                        </Grid>




                    </div>
                </Animation>
        </Section>
        </Container>


    )


}


export default Profile